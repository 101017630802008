/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  /* margin-left: -10px;  */
  /* margin-right: -10px; */
  width: auto;
}
.my-masonry-grid_column {
  /* padding-left: 10px;  */
  /* padding-right: 10px; */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}



/* Style your items */


.my-masonry-grid_detail {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;/* gutter size offset */
  width: auto;
}

.lazy-load-image-background{
  display: block !important;
}

.ReactModal__Overlay{
  z-index: 9999 !important;
}

.arrow-prev{
  cursor: pointer;
}

.arrow-next{
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.banner-carousel .control-dots{
  bottom: 50px !important;
  right: calc((100% - 1120px) / 2);
  display: flex;
  width: auto !important;
}

@media only screen and (max-width: 1140px) {
  .banner-carousel .control-dots{
    bottom: 40px !important;
    right: calc(20px);
    display: flex;
  }  
}

@media only screen and (max-width: 899px) {
  .banner-carousel .control-dots{
    bottom: 40px !important;
    right: calc(5% + 10px);
    display: flex;
  }  
}

@media only screen and (max-width: 639px) {
  .banner-carousel .control-dots{
    bottom: 0px !important;
    right: calc(5% + 10px);
    display: flex;
  }  
}

.banner-top-sell .control-dots{
  bottom: 0px !important;
  display: flex;
  width: auto !important;
  padding-right: 10px !important;
  left: 50%;
  transform: translateX(-50%);
}


.banner-product-detail .control-dots{
  right: 0;
  top: 0;
  margin: 0px !important;
  padding: 0px 6px !important;
  display: flex;
  flex-direction: column;
  width: 70px !important;
}

/* @media only screen and (max-width: 1140px) {
  .banner-product-detail .control-dots{
    bottom: 40px !important;
    right: calc(20px);
    display: flex;
  }  
} */