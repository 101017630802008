.menuItem {
    user-select: none;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.menuItem.active{
    background-color: #d8d8d8;
    border-radius: 10px;
}
